'use client';

import React, {useEffect, useState} from 'react';
import {FaWhatsapp} from 'react-icons/fa';
import {CustomButton} from '.';
import {Link} from '@heroui/react';

const FloatingIcon = () => {
  const [showFloatingIcon, setShowFloatingIcon] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const footerElement = document.querySelector('.footer');
      if (footerElement) {
        const footerRect = footerElement.getBoundingClientRect();
        if (footerRect.top <= window.innerHeight) {
          setShowFloatingIcon(false);
        } else {
          setShowFloatingIcon(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!showFloatingIcon) {
    return null;
  }
  return (
    <div
      className={`fixed bottom-[3.5rem] z-[40] xl:bottom-[2rem] ltr:right-4 rtl:right-4`}>
      <CustomButton
        as={Link}
        href="https://wa.me/+966536679457"
        aria-label="Whatsapp Icon"
        isExternal
        iconOnly
        btnStyles="h-[3.5rem] w-[3.5rem] !min-w-9 bg-thistle sm:p-3 xl:p-2"
        value={<FaWhatsapp className="text-persianIndigo" size={32} />}
      />
    </div>
  );
};

export default FloatingIcon;
